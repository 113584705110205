import {L} from "vue2-leaflet";
<template>
  <base-form-item
      :field="field"
      :schemaVersion="schemaVersion"
  >
    <el-autocomplete
        :fetch-suggestions="querySearch"
        :placeholder="`${$t('system.search')}: ${$t('system.street')}, ${$t('system.city')}, ${$t('system.county')}, ${$t('system.state')}, ${$t('system.country')}, ${$t('system.postal_code')}`"
        :trigger-on-focus="false"
        @select="handleSelect"
        class="w-100 mb-2"
        popper-class="my-autocomplete"
        size="small"
        v-model="searchString"
    >
      <i
          class="el-icon-search el-input__icon"
          slot="suffix"
      >
      </i>
      <template slot-scope="{ item }">
        <div class="value">{{ item.label }}</div>
      </template>
    </el-autocomplete>
    <div
        class="mb-3"
        style="height: 500px; width:100%"
    >
      <l-map
          :center="value || [50, 50]"
          :max-zoom="meta.maxZoom || 18"
          :min-zoom="meta.minZoom || 1"
          :zoom="10"
      >
        <l-tile-layer url="https://api.maptiler.com/maps/basic/{z}/{x}/{y}.png?key=cSJ7m9qsgRjbKQInLyYn"></l-tile-layer>
        <l-marker
            :draggable="true"
            :lat-lng="value"
            @update:latLng="onMarkerDragged"
            v-if="value"
        >
          <!--          <l-tooltip-->
          <!--              v-if="value && value.tooltip"-->
          <!--              :content="value.tooltip"-->
          <!--          />-->
        </l-marker>
      </l-map>
    </div>
  </base-form-item>
</template>

<script>
import {LMap, LMarker, LTileLayer} from 'vue2-leaflet';
import {Icon} from 'leaflet';
import 'leaflet/dist/leaflet.css';
import {OpenStreetMapProvider} from 'leaflet-geosearch';
import BaseFormItem from '../../UI/FormItem.vue';
import abstractForm from '../../mixin/index';

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});
export default {
  name: 'FieldMapCreateOrUpdate',
  mixins: [abstractForm],
  components: {LMap, LTileLayer, LMarker, BaseFormItem},
  data() {
    return {
      searchString: '',
      geosearchOptions: { // Important part Here
        provider: new OpenStreetMapProvider(),
        autoClose: true,
        marker: {
          draggable: true,
        },
        searchLabel: 'Enter address',
        keepResult: true,
      },
    };
  },
  created() {
    if (this.meta.defaultPosition) {
      this.value = {...this.meta.defaultPosition};
    } else if (typeof this.value === 'string' && this.value) {
      this.value = JSON.parse(this.value);
    } else {
      this.value = [40, -100];
    }
  },
  methods: {
    onMarkerDragged(coordinates) {
      this.value = coordinates;
    },
    querySearch(queryString, cb) {
      const provider = new OpenStreetMapProvider();
      provider.search({query: queryString}).then((results) => {
        cb(results);
      });
    },
    handleSelect(item) {
      const provider = new OpenStreetMapProvider();
      provider.search({query: item.label});
      this.searchString = item.label;
      this.value = [item.y, item.x];
    },
  },
};
</script>

<style scoped>

</style>
